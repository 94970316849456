import { Box, Card, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

import PropTypes from 'prop-types';

import { Image } from '@/src/components/atoms';

const useStyles = makeStyles(theme => ({
  image: { margin: 'auto', width: '100%' },
  card: {
    transition: 'box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease',
    '&:hover': {
      boxShadow:
        '0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important',
      transform: 'translate3d(0,-5px,0)',
    },
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.light} -120%, ${theme.palette.background.paper} 100%)`,
  },
}));

const Network = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('sm'), { defaultMatches: true });

  return (
    <div className={className} {...rest}>
      <Card className={classes.card}>
        <Image
          src="/images/partners/mandala.png"
          width="100%"
          alt="Mandala"
          className={classes.image}
        />
        <Box p={4}>
          <Typography variant={isMd ? 'h4' : 'h5'} sx={{ fontWeight: 'bold' }} align="center">
            Efeito conexão em rede
          </Typography>
          <Typography variant={isMd ? 'h6' : 'subtitle1'} color="textSecondary" align="center">
            Todos os nossos clientes que utilizam a Eskolare podem se conectar entre si com apenas
            alguns cliques.
          </Typography>
          <Typography variant={isMd ? 'h6' : 'subtitle1'} color="textSecondary" align="center">
            Faça negócios dentro da rede Eskolare! 😊
          </Typography>
        </Box>
      </Card>
    </div>
  );
};

Network.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Network;
